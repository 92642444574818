import React, { useEffect, useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import Brand from "../../components/Brand";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import NotFoundPage from "../404";
import { getDataByUID } from "../../libs/prismic";
import { Col, Row } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th } from "../../components/Table";
import TableRow from "../../components/InvestTrade/PageContent/TableRow";
import OpalFile from "../../components/InvestTrade/PageContent/OpalFile";
import {
  showAlert,
  NOT_VERIFIED_OPAL_BUY,
  NOT_VERIFIED_DEEP_OPAL_INFO,
  SUBSEQUENT_LOG_INS,
} from "../../store/reducers/alert";

import {
  actionTypes,
} from "../../store/Dialog/type";

import {
  actionTypes as appActionTypes,
} from "../../store/App/type";

import { client as prismicClient } from "../../libs/prismic";
import { isAfter5days, sortOpals } from "../../utils/validationFunc";
import { moduleTypes } from "../../store/type";
import "../../styles/components/InvestTrade/PageContent/style.scss";
import "../../styles/pages/investtrade.scss";

const InvestTrade = ({
  slug,
  location,
  showAlert,
}) => {
  const dispatch = useDispatch();

  const [brandsDoc, setBrandsDoc] = useState();
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [opals, setOpals] = useState([]);
  const [keyMemberNames, setKeyMemberNames] = useState([]);
  const [blogItems, setBlogItems] = useState();

  let blogData;

  const accounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const refreshKey = useSelector(
    (state) => state?.dialog?.refreshKey
  );

  const activities = useSelector(
    (state) => state?.activities?.activities
  );

  const type = useMemo(() => {
    const type = {
      qualified_purchaser: false,
      accredited_investor: false,
    };
    accounts.filter((el) => el.hasOwnProperty('zipcode')).forEach((account) => {
      type[account.qualification] = true;
    });
    return type;
  }, [accounts]);

  useEffect(async () => {
    async function fetchData(slug) {
      try {

        const response = await getDataByUID("opal_fund_partners", slug);
        if (response) {
          setBrandsDoc(response);
        }
      } catch (error) {
        setShowErrorPage(true);
      }
    }
    if (slug) {
      fetchData(slug);
    }
  }, [slug]);

  useEffect(async () => {
    if (brandsDoc?.data?.brand_key_members) {
      setKeyMemberNames(brandsDoc?.data?.brand_key_members.map((item) => item?.text))
    }
  }, [brandsDoc]);
  

  useEffect(async () => {
    if (brandsDoc) {
      let _opals = [];

      brandsDoc.data?.opals.forEach((opal) => {
        if (isAfter5days(opal) || !opal.display) return;

        if (opal.availability === 'Accredited and QP') {
          _opals.push({ opal });
        } else {
          if (opal.availability === 'Accredited Only') {
            if (type.accredited_investor === true) {
              _opals.push({ opal });
            }
          } else if (opal.availability === 'QP Only') {
            if (type.qualified_purchaser === true) {
              _opals.push({ opal });
            }
          }
        }
      });

      _opals.forEach(({ opal }) => {
        const activity = activities.find(activity => opal.opal_name === activity._id);
        if (activity) {
          opal.capPercent = Math.round(activity.total / opal.maximum_target_raise * 100);
        }
      });

      _opals = sortOpals(_opals);
      setOpals(_opals);
    }
  }, [type, brandsDoc, activities])
  
  const setDocData = (docData) => {
    let docDataMatched;
    docDataMatched = docData.filter((item) => item?.data?.post_to_opal_fund_partner.includes(brandsDoc?.data?.brand_image_title[0].text));
    blogData = docDataMatched.map((item) => ({
      "title" : item?.data?.title[0]?.text,
      "date": new Date(item?.data?.date),
      "uid": item?.uid,
      "type": item?.type
    }));

    blogData.sort((a, b) => b.date - a.date);
    blogData.splice(3);
    setBlogItems(blogData);
  }

  useEffect(async () => {  
    window.scroll(0, 0);
  }, []);

  useEffect(async () => {
      async function fetchAndSetData() {
      const blogPostDocResponse = await prismicClient().getAllByType("blog_post");
      const pressMediaDocResponse = await prismicClient().getAllByType("press_media_post");
      const docDataValue = [...blogPostDocResponse, ...pressMediaDocResponse];

      setDocData(docDataValue);
    }

    if (brandsDoc) {
      fetchAndSetData();
    }
  }, [brandsDoc])

  useEffect(async () => {
    if (refreshKey !== '') {
      dispatch({ type: appActionTypes.LOAD_ACCOUNTS_DATA, value: null })
    }
  }, [refreshKey])

  const buyHandler = (opal, brand) => {
    if (accounts.length > 0 && !accounts[0].hasOwnProperty('approvalTemplate')) {
      const alertType = { type: NOT_VERIFIED_OPAL_BUY };
      showAlert({ ...alertType, subType: 'non-ria' });
      return;
    } else {
      if (accounts.length > 0 && accounts[0].type === 'ria') {
        if (accounts.slice(1).filter(el => el.hasOwnProperty('approvalTemplate')).length === 0) {
          const alertType = { type: NOT_VERIFIED_OPAL_BUY };
          showAlert({ ...alertType, subType: 'ria' });
          return;
        }
      } else {
        if (accounts.filter(el => el.hasOwnProperty('approvalTemplate')).length === 1 &&
          ((accounts.filter(el => el.hasOwnProperty('approvalTemplate'))[0].qualification === 'accredited_investor' && opal.availability !== 'QP Only') ||
            (accounts.filter(el => el.hasOwnProperty('approvalTemplate'))[0].qualification === 'qualified_purchaser' && opal.availability !== 'Accredited Only'))) {
          dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
          dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_payment', show: true, fromBack: false, payment: null, cost: '0', account: accounts.filter(el => el.hasOwnProperty('approvalTemplate'))[0] } });
          return
        }
      }
      dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_select_accounts', show: true, payment: null } });
    }
  }

  const shareOpalHandler = (opal, brand) => {
    if (accounts.length > 0 && !accounts[0].hasOwnProperty('approvalTemplate')) {
      const alertType = { type: NOT_VERIFIED_OPAL_BUY };
      showAlert({ ...alertType, subType: 'non-ria' });
      return;
    } else {
      if (accounts.length > 0 && accounts[0].type === 'ria') {
        if (accounts.slice(1).filter(el => el.hasOwnProperty('approvalTemplate')).length === 0) {
          const alertType = { type: NOT_VERIFIED_OPAL_BUY };
          showAlert({ ...alertType, subType: 'ria' });
          return;
        }
      }
      dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_share_opal', show: true, payment: null, sentAccounts: [] } });
    }
  }

  const mediaHandler = (url, label, opal_name) => {
    if (accounts.filter(el => el.hasOwnProperty('approvalTemplate')).length === 0) {
      let subType;
      const alertType = { type: NOT_VERIFIED_DEEP_OPAL_INFO };
      
      if (accounts.length > 0 && accounts[0].type === 'ria') {
        subType = 'ria';
      } else {
        subType = 'non-ria';
      }

      showAlert({ ...alertType, subType });
      return;
    } else {
      if (url.split('.').pop() === 'pdf') {
        let new_window;

        new_window = window.open('', '_blank', 'toolbar=0,location=0,menubar=0,popup=1,titlebar=0,location=0');

        let html_contents = `<embed src="${url}" style="width: 100%; height: 100vh"/>`;

        new_window.document.write('<!doctype html><html><head><title></title><meta charset="UTF-8" /><meta name="viewport" content="width=device-width"></head><body style="background-color: rgb(0, 0, 0);margin:0px">' + html_contents + '</body></html>');

      } else {
        dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_video', show: true, payment: null, data: {opal_name, label, url} } });
      }
    }
  }

  return brandsDoc ? (
    <Layout>
      <Brand
        image={brandsDoc && brandsDoc?.data?.brand_image?.url}
        heading1={brandsDoc && brandsDoc?.data?.brand_heading_1[0]?.text}
        description={brandsDoc && brandsDoc?.data?.brand_description_1[0]?.text}
        imageTitle={brandsDoc && brandsDoc?.data?.brand_image_title[0]?.text}
        amount={brandsDoc && brandsDoc?.data?.brand_amount[0]?.text}
        keyMembers={brandsDoc && keyMemberNames}
        address={brandsDoc && brandsDoc?.data?.brand_address[0]?.text}
        blogItems={blogItems}
        className="mb-62"
      />
      <Row className="justify-content-center mx-auto mw-1440 investtrade_content">
        <Col>
          <Col md={12} className="d-none d-md-block" style={{borderBottom: "none"}}>
            <table className="opal-file-table responsiveTable">
              <Thead>
                <Tr>
                  <Th style={{ width: '30%' }}>Initial Opal Offerings</Th>
                  <Th style={{ width: '32%' }}>Strategy</Th>
                  <Th style={{ width: '15%' }} className="text-right">Minimum </Th>
                  <Th style={{ minWidth: '124px' }} className="text-right">Availability</Th>
                  <Th style={{ width: '15%' }}></Th>
                </Tr>
              </Thead>
            </table>
            {opals?.map((item, index) => {
              const isWithBreakItem = opals.length > 1 && index === 1
              return (
                <>
                <React.Fragment key={index}>
                  <Table className={`opal-file-table ${isWithBreakItem ? 'break-line' : ''}`}>
                    <Thead>
                      <Tr className="no-border">
                        <Th style={{ width: '30%' }}></Th>
                        <Th style={{ width: '32%' }}></Th>
                        <Th style={{ width: '15%' }} className="text-right"></Th>
                        <Th style={{ minWidth: '124px' }} className="text-right"></Th>
                        <Th style={{ width: '15%' }}></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <TableRow
                        key={index}
                        brand={brandsDoc}
                        opal={item.opal}
                        buyHandler={buyHandler}
                        mediaHandler={mediaHandler}
                        shareOpalHandler={shareOpalHandler}
                      />
                    </Tbody>
                  </Table>
                  
                  <OpalFile
                    key={index}
                    brand={brandsDoc}
                    opal={item.opal}
                    buyHandler={buyHandler}
                    mediaHandler={mediaHandler}
                    shareOpalHandler={shareOpalHandler}
                  />
                </React.Fragment>
                </>);
            })}
          </Col>

          <Col md={12} className="initial-offerings d-md-none">
            <b>Initial Opal Offerings</b>
          </Col>
          <Col md={12} className="d-md-none">
            <Table>
              <Tbody>
                {opals?.map((item, index) => {
                  return (
                    <>
                      <TableRow
                        key={index}
                        brand={brandsDoc}
                        opal={item.opal}
                        buyHandler={buyHandler}
                        mediaHandler={mediaHandler}
                        shareOpalHandler={shareOpalHandler}
                      />
                      <OpalFile
                        key={index}
                        brand={brandsDoc}
                        opal={item.opal}
                        buyHandler={buyHandler}
                        mediaHandler={mediaHandler}
                        shareOpalHandler={shareOpalHandler}
                      />
                    </>
                  )
                })}
              </Tbody>
            </Table>
          </Col>
        </Col>
      </Row>
    </Layout>
  ) : showErrorPage ? (
    <NotFoundPage />
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  activeStep: state[moduleTypes.INVESTOR_QUALIFICATION].activeStep,
  isLoggedIn: state[moduleTypes.APP].isLoggedIn,
})

function mapDispatchToProps(dispatch) {
  return {
    showAlert: (...rest) => {
      dispatch(showAlert(...rest))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvestTrade);
